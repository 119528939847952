import React from "react";
import { Link } from "gatsby";

function FeaturedItem({ currentProduct }) {
  return (
    <div className="hidden lg:flex lg:flex-col rounded border border-grey-dark shadow-md w-1/2 lg:w-2/3 mx-auto my-2 pb-4">
      <h2
        className={`text-sm md:text-xl font-medium tracking-wide bg-${process.env.THEME_COLOR_PRIMARY} text-white w-full text-center p-2 md:py-8`}
      >
        <Link
          to={currentProduct.product.featuredItemLinkSlug}
          className="text-white no-underline"
        >
          {currentProduct.product.featuredItemTitle}
        </Link>
      </h2>
      <div className="w-full">
        <Link to={currentProduct.product.featuredItemLinkSlug}>
          <img
            src={currentProduct.product.featuredItemImage.localFile.publicURL}
            alt={currentProduct.product.featuredItemImage.alt}
            title={currentProduct.product.featuredItemImage.title}
          />
        </Link>
      </div>
      <div
        className="p-4 text-grey-darker"
        dangerouslySetInnerHTML={{
          __html: currentProduct.product.featuredItemDescription,
        }}
      />
      <Link
        to={currentProduct.product.featuredItemLinkSlug}
        className={`bg-${process.env.THEME_COLOR_PRIMARY} text-white text-sm text-center px-4 py-3 no-underline rounded uppercase shadow md:w-2/3 mx-auto`}
      >
        {currentProduct.product.featuredItemLinkText}
      </Link>
    </div>
  );
}

export default FeaturedItem;
