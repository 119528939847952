import Img from "gatsby-image";
import React from "react";

export default function Measure({ product }) {
  if (!product.sizeGuideText && !product.sizeGuideImage) {
    return "";
  }

  const imagesResolutions =
    product.sizeGuideImage.localFile.childImageSharp.fluid;

  return (
    <div className="container measure-guide mx-auto my-12 pt-12">
      <hr className="border-b border-grey-light mb-8" />
      <h2 className="text-center py-8 mb-4">Measuring Guide</h2>
      <div className="max-w-2xl m-auto flex items-center flex-wrap pb-16">
        <div className="w-full md:w-1/2 p-8">
          <Img
            className="w-full"
            fluid={imagesResolutions}
            key={imagesResolutions.src}
          />
        </div>
        <div
          className="w-full p-4 md:w-1/2 text-center"
          dangerouslySetInnerHTML={{ __html: product.sizeGuideText }}
        />
      </div>
    </div>
  );
}
