import { navigate } from "gatsby";
import React, { useContext, useEffect, useState, useMemo } from "react";
import slugify from "slugify";
import ServiceAreaForm from "../components/partials/service-areas";
import CartContext from "../context/cart-context";
import getSeason from "../utilities/get-season";
import ordinalNumberify from "../utilities/ordinal-numberify";
import { formatPrice } from "../utilities/price";
import toTitleCase from "../utilities/to-title-case";
import { useLocation } from "@reach/router";
import { parse } from "query-string";

/* eslint-disable jsx-a11y/no-onchange */

function VariableProductTemplate(props) {
  const cartContext = useContext(CartContext);
  const [style, setStyle] = useState("");
  const [sizes, setSizes] = useState(false);
  const [description, setDescription] = useState(props.currentProduct.content);
  const [errorMessage, setErrorMessage] = useState("");
  const [iDontKnowMessage, setIDontKnowMessage] = useState("");
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);
  const [salePriceDisplay, setSalePriceDisplay] = useState(0);
  const [basePriceDisplay, setBasePriceDisplay] = useState(0);
  const location = useLocation();
  const search = parse(location.search);

  const currentProduct = props.currentProduct.product;

  const { updateGallery, updateVariation } = props;

  const defaultSalePrice = currentProduct.salePrice;
  const defaultBasePrice = currentProduct.basePrice;

  useEffect(() => {
    let queriedSize = undefined;
    const size = decodeURI(search.size);
    const style = search.style ? decodeURI(search.style) : "";

    if (size) {
      queriedSize = currentProduct.sizes.find((item) => {
        return item.size === size;
      });
    }
    if (queriedSize) {
      setSizes([size]);
      setSalePriceDisplay(
        defaultSalePrice + (queriedSize.extraCost ? queriedSize.extraCost : 0)
      );
      setBasePriceDisplay(
        defaultBasePrice + (queriedSize.extraCost ? queriedSize.extraCost : 0)
      );
    } else {
      setSizes([""]);
      setSalePriceDisplay(defaultSalePrice);
      setBasePriceDisplay(defaultBasePrice);
    }

    if (style) {
      const selStyle = currentProduct.styles?.find(
        (item) => item.style === style
      );
      if (selStyle) {
        setStyle(style);
      }
    }
  }, [search.size, search.style, currentProduct.sizes, currentProduct.styles]);

  useEffect(() => {
    if (currentProduct.styles) {
      const selStyle = currentProduct.styles.find(
        (item) => item.style === style
      );

      if (selStyle) {
        updateGallery(selStyle.styleGallery);
        updateVariation(selStyle);
        setDescription(selStyle.styleDescription);
      }
    }
  }, [style, currentProduct.styles]);

  const scrollInstructions = () => {
    document.querySelector(".measure-guide").scrollIntoView({
      behavior: "smooth",
    });
  };

  const checkIfError = (submitting) => {
    if (submitting && !hasTriedToSubmit) {
      setHasTriedToSubmit(true);
    }

    if (currentProduct.styles && style === "") {
      setErrorMessage("Please choose a style");
      return true;
    }

    const unfilled = sizes.filter((size) => {
      return size.length ? false : "missing";
    });
    if (unfilled.length) {
      if (submitting || hasTriedToSubmit) {
        setErrorMessage("Please fill out all the fields");
      } else {
        setErrorMessage("");
      }
      return true;
    }

    setErrorMessage("");
    return false;
  };

  const outOfStockWarning = useMemo(() => {
    if (!currentProduct.styles) {
      return false;
    }
    const selStyle = currentProduct.styles.find((item) => item.style === style);

    if (selStyle?.outOfStock && selStyle?.outOfStockLongDescription) {
      return selStyle.outOfStockLongDescription;
    }

    return false;
  }, [style, currentProduct.styles]);

  if (!currentProduct.basePrice && !currentProduct.salePrice) {
    return (
      <div dangerouslySetInnerHTML={{ __html: props.currentProduct.content }} />
    );
  }

  return (
    <div>
      <div className="block md:flex md:flex-wrap items-start justify-between mb-2 lg:mb-0">
        <h1
          className="mt-0 font-semibold text-2xl w-full"
          dangerouslySetInnerHTML={{
            __html: currentProduct.mainH1
              ? `${style ? `${style} - ` : ""}${currentProduct.mainH1}`
              : `${style ? `${style} - ` : ""}${props.currentProduct.title}`,
          }}
        />
      </div>
      {salePriceDisplay ? (
        <div className="mt-3 flex flex-wrap justify-between">
          <p className="inline-block text-lg text-red-dark">
            <em>{toTitleCase(getSeason())} Special!</em>
          </p>
          <div className="flex text-right">
            <div className="flex flex-wrap w-auto items-center justify-center">
              <div className="text-grey-darker flex-grow">
                <p>
                  Sale Ends <strong>{props.saleEnds.date}</strong> &ndash;{" "}
                  {props.saleEnds.days} Days Left!
                </p>
                <p className="text-xs italic">*Online Only</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p
          className={`text-sm text-${process.env.THEME_COLOR_PRIMARY}-dark mb-8`}
        >
          * Expert installation add-on available at checkout
        </p>
      )}
      <div className="flex flex-wrap items-center w-full mt-3">
        <p className="w-full md:w-auto">
          <span
            className={`${
              !salePriceDisplay ? "hidden" : "line-through text-lg"
            }`}
          >
            {formatPrice(basePriceDisplay)}
          </span>
          <span className="text-2xl pl-2">
            {formatPrice(salePriceDisplay || basePriceDisplay)}
          </span>
        </p>
      </div>
      <div className="block mt-4 mb-8">
        <div className="lg:flex lg:justify-between">
          <div className="mr-4 mb-4">
            <label className="flex items-center justify-start">
              Quantity:{" "}
              <select
                name="quantity"
                type="number"
                className="bg-white w-18 shadow appearance-none border border-grey-light rounded ml-4 py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                onChange={(e) => {
                  const newSizes = [];
                  for (let index = 0; index < +e.currentTarget.value; index++) {
                    newSizes.push(sizes[index] || "");
                  }
                  setSizes(newSizes);
                }}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
              </select>
            </label>
          </div>
        </div>
        <div className="flex flex-wrap ">
          {currentProduct.styles && (
            <div className="mb-6 pr-3 py-2 w-full max-w-sm">
              <div className="shadow-md bg-grey-lightest p-4">
                <label>
                  <p>Select style</p>
                  <select
                    name="style"
                    className="w-full bg-white shadow appearance-none border border-grey-light rounded py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                    onChange={(e) => {
                      setStyle(e.currentTarget.value);
                    }}
                    required
                  >
                    <option value="">Select style</option>
                    {currentProduct.styles.map((item, index) => {
                      return (
                        <option
                          key={index}
                          value={item.style}
                          selected={item.style === style}
                        >
                          {item.style}{" "}
                          {item.outOfStock &&
                            item.outOfStockShortDescription &&
                            ` - ${item.outOfStockShortDescription}`}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </div>
            </div>
          )}
        </div>
        {outOfStockWarning && (
          <div className="mb-3 p-2 border border-red text-red max-w-sm">
            {outOfStockWarning}
          </div>
        )}
        <div className="flex flex-wrap ">
          {sizes &&
            sizes.map((size, index) => {
              return (
                <div key={index} className="pr-3 py-2 w-full max-w-sm">
                  <div className="shadow-md bg-grey-lightest p-4">
                    <label>
                      <p>
                        Select size of{" "}
                        <strong>{ordinalNumberify(index + 1)}</strong>{" "}
                        {props?.currentProduct?.product?.shortLabel}
                      </p>
                      <select
                        name="size"
                        className="w-full bg-white shadow appearance-none border border-grey-light rounded py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                        onChange={(e) => {
                          const extraCost = currentProduct.sizes.find(
                            (item) => {
                              return item.size === e.currentTarget.value;
                            }
                          )?.extraCost;

                          if (extraCost) {
                            setSalePriceDisplay(
                              defaultSalePrice + (extraCost ? extraCost : 0)
                            );
                            setBasePriceDisplay(
                              defaultBasePrice + (extraCost ? extraCost : 0)
                            );
                          } else {
                            setSalePriceDisplay(defaultSalePrice);
                            setBasePriceDisplay(defaultBasePrice);
                          }

                          const newSizes = sizes;
                          newSizes[index] = e.currentTarget.value;
                          setSizes(newSizes, checkIfError(false));
                          if (
                            newSizes.filter((size) => {
                              return size === "I don't know my size";
                            }).length
                          ) {
                            setIDontKnowMessage(
                              "All sizes will need to be provided at pick up or prior to shipping"
                            );
                          } else {
                            setIDontKnowMessage("");
                          }
                        }}
                        required
                        defaultValue={sizes[0] ? sizes[0] : ""}
                      >
                        <option value="">Select size</option>
                        {currentProduct.sizes.map((item, index) => (
                          <option key={index} value={item.size}>
                            {item.size}
                            {item.extraCost && (
                              <> + {formatPrice(item.extraCost)}</>
                            )}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </div>
              );
            })}
        </div>
        <div className={`${errorMessage ? "py-2 text-red-darker" : "hidden"}`}>
          {errorMessage}
        </div>
        <div
          className={`${
            iDontKnowMessage ? "py-2 text-orange-dark text-base" : "hidden"
          }`}
        >
          {iDontKnowMessage}
        </div>
        {currentProduct.category[0] === "covers" && (
          <div>
            <button
              onClick={scrollInstructions}
              className=" italic text-grey-darker text-sm"
            >
              How do I measure my window well?
            </button>
          </div>
        )}
        <button
          onClick={() => {
            setErrorMessage("");
            if (!checkIfError(true)) {
              sizes.forEach((size, index) => {
                const extraCost = currentProduct.sizes.filter((item) => {
                  return item.size === size;
                })[0].extraCost;

                const selStyle = style
                  ? currentProduct.styles.find((item) => item.style === style)
                  : null;

                const image = style
                  ? currentProduct.styles.find((item) => item.style === style)
                      ?.styleGallery[0]?.localFile?.publicURL
                  : props.currentProduct.featuredImage.node.localFile.publicURL;

                cartContext.addItemToCart(
                  {
                    id: slugify(
                      `${currentProduct.sku}-${size}${
                        style ? "-" + style : ""
                      }`,
                      {
                        lower: true,
                        strict: true,
                      }
                    ),
                    taxable: currentProduct.taxable,
                    title: props.currentProduct.title,
                    category: currentProduct.category,
                    basePrice: +currentProduct.basePrice,
                    salePrice: +currentProduct.salePrice,
                    style: style,
                    size: size.replaceAll('"', ""),
                    extraCost: extraCost || 0,
                    quantity: 1,
                    url: `/${props.currentProduct.slug}/`,
                    image: image,
                    discount:
                      selStyle && selStyle.discounted
                        ? selStyle.discountAmount
                        : 0,
                  },
                  false
                );
              });
              navigate("/add-accessories-to-your-cart");
            }
          }}
          className={`w-full md:w-auto mt-4 block md:inline-block bg-${process.env.THEME_COLOR_PRIMARY} text-white text-sm px-4 py-3 no-underline rounded h-10 uppercase`}
        >
          Add To Cart
        </button>
        <ServiceAreaForm />

        <h3>{props.currentProduct.name}</h3>
      </div>
      <hr className="border-b border-grey-light mb-8" />
      <div
        className="wp-content default-product"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
}
export default VariableProductTemplate;
