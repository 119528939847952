import React from "react";

const content = [
  "Measure your window wells that need covers",
  "Select the closest size from our in-stock inventory for each and add to your cart",
  `Choose pick up at our ${process.env.ADDRESS_CITY} headquarters or delivery within our delivery service area`,
  "Finalize and pay for your order",
  "Simple online checkout and immediate order confirmation affirm your order has been received and your products are ready to go",
  "Pick up or receive delivery",
  "Install and enjoy peace of mind for your family, a more finished appearance for your house!",
  "You’re happy, we’re happy",
];

const Item = ({ content, index }) => {
  return (
    <li className="w-full md:w-1/2 lg:w-1/4 text-center p-4">
      <div
        className={`text-xl md:text-6xl text-${process.env.THEME_COLOR_PRIMARY} font-bold tracking-tighter rounded-full border-solid border-4 border-${process.env.THEME_COLOR_PRIMARY} w-12 h-12 flex items-center justify-center mx-auto my-4`}
      >
        {index + 1}
      </div>
      <div>{content}</div>
    </li>
  );
};

export default function OurProcess() {
  return (
    <div className="container mx-auto mb-12">
      <hr className="border-b border-grey-light mb-8" />
      <h2 className="text-center py-8 mb-4">Our order process is simple</h2>
      <ol className="flex flex-wrap">
        {content.map((item, index) => (
          <Item key={index} content={item} index={index} />
        ))}
      </ol>
    </div>
  );
}
