import React, { useContext, useState } from "react";
import CartContext from "../context/cart-context";
import { formatPrice } from "../utilities/price";

/* eslint-disable jsx-a11y/no-onchange */

function SimpleProductTemplate(props) {
  const { addItemToCart, getItemDiscount } = useContext(CartContext);
  const [quantity, setQuantity] = useState(1);

  if (
    !props.currentProduct.product.basePrice &&
    !props.currentProduct.product.salePrice
  ) {
    return (
      <div dangerouslySetInnerHTML={{ __html: props.currentProduct.content }} />
    );
  }

  const price = getItemDiscount(props.currentProduct.product);

  return (
    <div>
      <div className="block md:flex md:flex-wrap items-start justify-between mb-2 lg:mb-0">
        <h1
          className="mt-0 font-semibold text-2xl w-full"
          dangerouslySetInnerHTML={{
            __html: props.currentProduct.product.mainH1
              ? props.currentProduct.product.mainH1
              : props.currentProduct.title,
          }}
        />
      </div>
      <p className={`text-sm text-${process.env.THEME_COLOR_PRIMARY}-dark`}>
        * Expert installation add-on available at checkout
      </p>
      <div className="flex flex-wrap items-center my-8 w-full">
        <p className="w-full md:w-auto">
          <span
            className={`${
              price === props.currentProduct.product.basePrice ? "hidden " : ""
            }line-through text-lg`}
          >
            {formatPrice(props.currentProduct.product.basePrice)}
          </span>
          <span className="text-2xl pl-2">{formatPrice(price)}</span>
        </p>
      </div>

      <div className="block md:flex items-end mb-8">
        <div className="mr-4">
          <label className="flex items-center justify-between">
            Quantity:{" "}
            <select
              name="quantity"
              type="number"
              className="bg-white w-24 shadow appearance-none border border-grey-light rounded ml-2 py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
              onChange={(e) => {
                setQuantity(+e.currentTarget.value);
              }}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="20">21</option>
              <option value="20">22</option>
              <option value="20">23</option>
              <option value="20">24</option>
              <option value="20">25</option>
              <option value="20">26</option>
              <option value="20">27</option>
              <option value="20">28</option>
              <option value="20">29</option>
              <option value="20">30</option>
            </select>
          </label>
        </div>
        <button
          onClick={() => {
            addItemToCart(
              {
                id: props.currentProduct.product.sku,
                taxable: props.currentProduct.product.taxable,
                title: props.currentProduct.title,
                basePrice: +props.currentProduct.product.basePrice,
                salePrice: +props.currentProduct.product.salePrice,
                category: props.currentProduct.product.category,
                quantity: +quantity,
                url: `/${props.currentProduct.slug}/`,
                image:
                  props.currentProduct.featuredImage.node.localFile.publicURL,
              },
              true
            );
            return true;
          }}
          className={`w-full md:w-auto mt-4 md:mt-0 block md:inline-block bg-${process.env.THEME_COLOR_PRIMARY} text-white text-sm px-4 py-3 no-underline rounded h-10 uppercase`}
        >
          Add To Cart
        </button>
        <h3>{props.currentProduct.name}</h3>
      </div>
      <hr className="border-b border-grey-light mb-8" />
      <div
        className="wp-content default-product"
        dangerouslySetInnerHTML={{ __html: props.currentProduct.content }}
      />
    </div>
  );
}
export default SimpleProductTemplate;
