import { Field, Form, Formik } from "formik";
import { Link } from "gatsby";
import React, { useContext, useEffect, useRef } from "react";
import CartContext from "../../context/cart-context";
import { allDeliverableZipCodes } from "../../data/zip-codes";
import { shippingPrice } from "../../templates/page-templates/service-areas";

const ServiceAreaForm = () => {
  const cartContext = useContext(CartContext);
  const formikRef = useRef(null);

  useEffect(() => {
    async function validate() {
      await formikRef.current.validateForm();
    }
    validate();
  }, []);

  return (
    <div className="w-full lg:w-1/2 pt-4  text-grey-darker max-w-2xl wp-content">
      <Formik
        innerRef={formikRef}
        initialValues={{
          shippingZip: cartContext.checkoutInfo.shippingZip ?? "",
        }}
        validate={(values) => {
          let errors = {};

          if (!values.shippingZip) {
            errors.shippingZip = "Required";
          } else if (values.shippingZip.length !== 5) {
            errors.shippingZip = "Please enter a valid zip code";
          } else if (
            allDeliverableZipCodes.indexOf(parseInt(values.shippingZip)) < 0
          ) {
            errors.shippingZip = `We do not currently deliver to that area. You can still order and pick up your order from our ${process.env.ADDRESS_CITY} location. Please call us for more information.`;
          }

          cartContext.setCheckoutInfo({
            ...cartContext.checkoutInfo,
            ...values,
          });

          return errors;
        }}
        onSubmit={(values) => {
          console.log(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors }) => (
          <Form className="border-b border-grey-lighter">
            <div className="w-full relative">
              <label
                htmlFor="shippingZip"
                className="block mb-2 text-md lg:text-lg xl:text-xl"
              >
                <strong>Are you in our service area?</strong>
              </label>
              <Field
                type="text"
                name="shippingZip"
                placeholder="Enter your zip code"
                className="shadow appearance-none border border-grey-light rounded w-full  md:w-1/2 lg:w-full px-3 text-grey-darker leading-tight focus:outline-none focus:ring"
              />
              <p
                className={`${
                  !errors.shippingZip && values.shippingZip.length === 5
                    ? `text-${process.env.THEME_COLOR_PRIMARY}-darker w-full md:w-1/2  px-3 lg:w-full`
                    : "hidden "
                }`}
              >
                {shippingPrice(cartContext.checkoutInfo.shippingZip)}
              </p>
              <p
                className={`${
                  values.shippingZip.length === 5 && errors.shippingZip
                    ? "text-orange-dark w-full md:w-1/2  px-3 lg:w-full"
                    : "hidden"
                }`}
              >
                We do not currently deliver to that area. You can still order
                and pick up your order from our {process.env.ADDRESS_CITY}{" "}
                location. Please{" "}
                <Link to="/contact-us/" className="text-orange-dark">
                  call us
                </Link>{" "}
                for more information.
              </p>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ServiceAreaForm;
